<template>
    <div class="entryPoints">
        <v-row>
            <v-col cols="12">
                <div class="masonry" >
                    <v-card rounded="lg" v-for="type in entryPointsType" :key="type" class="entry-brick">
                        <div class="d-flex justify-space-between align-center">
                            <h2 class="mb-0" style="min-height:unset">{{$t(type)}}</h2>
                            <v-btn depressed class="bg-gradient" v-if="$func.hasRight('entrypoint/add')" @click="openForm(type)">
                                <v-icon dark small class="mr-2"> $plus_l </v-icon>
                                {{$t('add')}}
                            </v-btn>
                        </div>

                        <div v-if="entryPoints[type]">
                            <div v-for="entrypoint in entryPoints[type]" :key="entrypoint.id" :id="'entrypoint_' + entrypoint.id" class="d-flex justify-space-between align-center item pa-3 mt-3" style="position: relative;">
                                <div class="itemLabel pa-2">
                                    <span v-if="entrypoint.config.name">{{$t('name')}} : {{entrypoint.config.name}} <br/></span>
                                    <span v-if="entrypoint.type == 'entryLanding' && entrypoint.config.subdomain && entrypoint.config.domain">{{$t('url')}} : <a :href="'https://' + entrypoint.config.subdomain + '.' + entrypoint.config.domain + '?operation_id=' + operationId" target="blank">{{entrypoint.config.subdomain + '.' + entrypoint.config.domain}}</a> <br/></span>
                                    <span v-if="entrypoint.type == 'entryEmail' && entrypoint.config.subdomain && entrypoint.config.domain">{{$t('url')}} : {{entrypoint.config.subdomain + '@' + entrypoint.config.domain}} <br/></span>
                                    <span v-if="entrypoint.config.entity && entrypoint.config.entity.text">{{$t('entity')}} : {{entrypoint.config.entity.text}} <br/></span>
                                    <span v-if="entrypoint.config.places">{{$t('places')}} : {{ entrypoint.config.places.map(({ text }) => text).join(', ') }} <br/></span>
                                    <span v-if="entrypoint.config.who_manage_new_leads && entrypoint.config.who_manage_new_leads === 'call'">{{$t('who_manage_new_leads')}} : {{entrypoint.config.call.text}} <br/></span>
                                    <span v-if="entrypoint.config.who_manage_new_leads && entrypoint.config.who_manage_new_leads === 'vendor'">{{$t('who_manage_new_leads')}} : {{$t('vendor')}} <br/></span>
                                    <span v-if="entrypoint.config.who_manage_new_leads && entrypoint.config.who_manage_new_leads === 'vendorspool'">{{$t('who_manage_new_leads')}} : {{$t('vendorspool')}} <br/></span>
                                    <span v-if="entrypoint.type == 'entryCallIn' && entrypoint.config.district">{{$t('entryCallInDistrictTitle')}} : {{entrypoint.config.district}} <br/></span>
                                    <span v-if="entrypoint.type == 'entryCallIn' && entrypoint.config.number">{{$t('phoneNumber')}} : {{phoneNumberToClean(entrypoint.config.number)}} <br/></span>
                                    <span v-if="entrypoint.type == 'entryCallIn' && entrypoint.config.redirect && entrypoint.config.redirect_number === 'custom_phone'">{{$t('redirectNumber')}} : {{phoneNumberToClean(entrypoint.config.redirect)}} <br/></span>
                                    <span v-if="entrypoint.config.template">{{$t('call_script')}} : {{entrypoint.config.template.name}} <br/></span>
                                    <span v-if="entrypoint.type == 'entryApi' && entrypoint.config.uniqId">
                                        <transition name="fade">
                                            <div v-if="show[entrypoint.id]" class="copyDiv">
                                                <v-icon color="success" class="mr-2">$copy_d</v-icon>
                                                <h5 class="success--text" style="font-size:16px;">{{ $t('keyCopied') }}</h5>
                                            </div>
                                        </transition>
                                        {{$t('uniqId')}} : {{entrypoint.config.uniqId}}
                                        <input type="hidden" v-on:focus="$event.target.select()" :ref="'idApi_' + entrypoint.id" :value="entrypoint.config.uniqId" readonly>
                                    </span>
                                </div>
                                <div>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" @click=""  class="square float-right" depressed text>
                                                <v-icon >$dots_l</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item  v-if="entrypoint.type == 'entryLanding'" dense @click="" :href="'https://' + entrypoint.config.subdomain + '.' + entrypoint.config.domain+'?operation_id='+operationId" target="_blank">
                                                <v-list-item-title><v-icon dark color="primary" class="mr-2" small>$eye_d</v-icon>{{ $t('visitLanding') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-if="entrypoint.type == 'entryLanding' && $func.hasRight('entrypoint/update')" dense @click="changeLandingDialog(entrypoint)">
                                                <v-list-item-title><v-icon color="primary" class="mr-2" dark small >$switch_d</v-icon>{{ $t('changeModel') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-if="entrypoint.type == 'entryLanding' && $func.hasRight('entrypoint/update')" dense @click="openLandingDialog(entrypoint)">
                                                <v-list-item-title><v-icon color="primary" class="mr-2" dark small >$pencil_paintbrush_d</v-icon>{{ $t('modifyTemplate') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item  v-if="$func.hasRight('entrypoint/update')" dense @click="editItem(entrypoint.id)">
                                                <v-list-item-title><v-icon color="primary" class="mr-2" dark small>$pencil_d</v-icon>{{ $t('edit_setting') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item  v-if="entrypoint.type == 'entryApi'" dense @click="copyApiKey('idApi_' + entrypoint.id, entrypoint.id)">
                                                <v-list-item-title><v-icon dark color="primary" class="mr-2" small>$copy_d</v-icon>{{ $t('copyApikey') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item  v-if="$func.hasRight('entrypoint/delete')" dense @click="getDeleteConfirmationItem(entrypoint.id)">
                                                <v-list-item-title><v-icon dark color="error" class="mr-2" small>$trashalt_d</v-icon>{{ $t('delete') }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </div>
                        </div>
                        <v-alert border="left" class="mt-3" text dense color="info" v-else>
                            <template v-slot:prepend>
                                <v-icon color="info" class="mr-3" small>
                                    $warning_s
                                </v-icon>
                            </template>
                            {{$t('no' + type)}}
                        </v-alert>
                    </v-card>
                </div>
            </v-col>
        </v-row>

        <v-dialog width="600" v-model="dialogConfirm">
            <v-card>
                <v-toolbar flat dark color="primary" class="toolbar" :style="'background-image:url('+publicPath+'img/blue-background.jpg); background-size:cover; height:63px;'">
                    <v-toolbar-title>{{$t('confirmDeleteLabel')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn depressed outlined color="white" class="mr-3" @click="closeDialog">
                        {{$t('cancelLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" @click="deleteItem">
                        {{$t('confirmLabel')}}
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-3">
                    {{$t('confirmDeleteInscriptionStepText')}}
                </v-card-text>
            </v-card>
        </v-dialog>

        <FormDialogComponent
            v-if="form"
            ref="componentForm"
            :form="form"
            :action="'SET_DATA'"
            />
        <Loader :flat="true" v-if="loading" style="min-height:500px;"/>
        <landingPageEditor @reloadData="reloadDataLP" :dialogEditorLP="dialogEditorLP" :idLanding="idLanding" :css="css" :currentLanding="landing" :entrypoint="entrypoint" @closeDialog="closeDialog" :key="'lp_' + dialogKey"/>
        <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="'LANDINGPAGE'" @cancel="formLib = null;" @customRefresh="customRefresh" @selectModel="changeLandingModel" :operationCreatedId="operationId" :libParams="{ operation_id: this.operationId, entrypoint_id: entrypoint.id, inStepper: true }" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" />
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import getForm from "@/mixins/mixins";
import displayDatatableAlert from "@/mixins/mixins";
import Loader from '@/components/Loader'
import dateManipulation from '@/mixins/dateManipulation'
import landingPageEditor from '@/modules/landingpage/views/landingPageEditor';

export default {
    name:"inscriptionStepForm",
    components:{
        FormDialogComponent, 
        Loader,
        LibraryDialogComponent: () => import("@/components/dialogs/LibraryDialogComponent"),
        landingPageEditor
    },
    mixins: [getForm, dateManipulation, displayDatatableAlert],
    props:['operationId', 'loadData'],
    data() {
        return {
            form:null,
            publicPath: process.env.BASE_URL,
            dialogConfirm:false,
            itemToDelete: "",
            entryPointsType: [],
            entryPoints: {},
            actions: {},
            loading:false,
            showLibraryDialog: false,
            formLib:null,
            show: {},
            // DÉBUT LP
            idLanding: 0,
            entrypoint: {},
            css: {},
            landing: {},
            dialogEditorLP: false,
            dialogKey: 0,
            // FIN LP
        }
    },
    watch: {
        loadData: {
            immediate:true,
            handler(val){
                if(val){
                    this.loading = true
                    GenericDataService.getData('/operation/getEntryPointsTypes').then((response) => {
                        this.entryPointsType = response.data.data
                        GenericDataService.getData('/operation/'+this.operationId+'/getEntryPoints').then((response) => {
                            this.entryPoints = response.data.data
                            this.loading = false
                        })
                    })
                }
            }
        }
    },
    created() {


    },
    computed: {

    },
    methods: {
        customRefresh(response){
            if(response)
                this.entryPoints = response.data.data
            else {
                this.loading = true
                GenericDataService.getData('/operation/'+this.operationId+'/getEntryPoints').then((response) => {
                    this.entryPoints = response.data.data
                    this.loading = false
                })
            }

        },
        openForm(type){
            this.entrypoint = {};
            if(type == 'entryLanding'){
                GenericDataService.getData("/library/getFilters?libType=LANDINGPAGE&params[operation_id]=" + this.operationId).then((response) => {
                    this.formLib = response.data.data;
                    this.showLibraryDialog = true;
                    //this.libType = btn.openLibraryDialog;
                    //this.$emit("hideGlobalAddDialog");
                });
            } else {
                this.getForm('/operation/'+this.operationId+'/getFormEntryPoints?type='+type+'&stepper=true');
            }
        },
        editItem(idItem){
            this.getForm('/operation/'+this.operationId+'/getFormEntryPoints?id='+idItem+'&stepper=true')
        },
        closeDialog(){
            this.itemToDelete = ""
            this.dialogConfirm = false
            this.dialogEditorLP = false
        },
        deleteItem(){
            GenericDataService.getData('/operation/'+this.operationId+'/deleteEntryPoint?id='+this.itemToDelete+'&stepper=true').then((response) => {
                this.entryPoints = response.data.data
                this.dialogConfirm = false
                this.itemToDelete = ""
            })
        },
        getDeleteConfirmationItem(idItem){
            this.itemToDelete = idItem
            this.dialogConfirm = true
        },
        copyApiKey(apiRef, id){
            this.$set(this.show, id, true);
            this.$refs[apiRef][0].focus();
            document.execCommand('copy');
            navigator.clipboard.writeText(this.$refs[apiRef][0].value);
            setTimeout(() => {
                this.$set(this.show, id, false);
            }, 1000);
        },
        openLandingDialog(entrypoint) {
            if(!entrypoint.config.lp_setting) {
                GenericDataService.getData('/landingpage/getLandingStyleOperation?operation_id=' + this.operationId + '&url=' + entrypoint.config.subdomain + "." + entrypoint.config.domain).then((response) => {
                    this.idLanding = entrypoint.config.landingpage.id;
                    this.entrypoint = entrypoint;
                    this.css = response.data.data.css;
                    this.landing = response.data.data.landing;
                    if((this.landing.settings && this.landing.settings.date_end && this.landing.settings.date_end == "") || !this.landing.settings.date_end) {
                        delete this.landing.hooks.lp_countdown;
                    }
                    this.dialogEditorLP = true
                })
            }
        },
        reloadDataLP(entrypoint_id) {
            let entryp = this.entryPoints.entryLanding.find((e) => e.id == entrypoint_id);
            if(!entryp.config.lp_setting) {
                GenericDataService.getData('/landingpage/getLandingStyleOperation?operation_id=' + this.operationId + '&url=' + entryp.config.subdomain + "." + entryp.config.domain).then((response) => {
                    this.idLanding = entryp.config.landingpage.id;
                    this.entrypoint = entryp;
                    this.css = response.data.data.css
                    this.landing = response.data.data.landing
                    if((this.landing.settings && this.landing.settings.date_end && this.landing.settings.date_end == "") || !this.landing.settings.date_end) {
                        delete this.landing.hooks.lp_countdown
                    }
                })
            }
        },
        changeLandingDialog(entrypoint) {
            GenericDataService.getData("/library/getFilters?libType=LANDINGPAGE&params[operation_id]=" + this.operationId).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
                this.entrypoint = entrypoint;
            });
        },
        changeLandingModel(model) {
            let url = "/operation/" + this.operationId + "/setOpObject?parentField=entrypoints&type=entryLanding&model_id=" + model.id + "&id=" + this.entrypoint.id;
            GenericDataService.postData(url, this.entrypoint.config).then((response) => {
                this.customRefresh();
                this.displayDatatableAlert(response);
            });
        }
    },
}
</script>
<style lang="scss">
    .masonry {
        margin: 0 auto 0 auto;
        column-gap: 2em;
        column-count: 2;
    }
    .entry-brick {
        display: inline-block;
        margin: 0 auto 2em auto;
        padding:25px;
        width: 100%;
        .item {
            background-color: var(--v-lightgrey-base);
            border-radius: 5px;
            .itemLabel {
                font-size:15px;
            }
        }
    }
    .InscriptionfieldsContainer {
        width: 100%;
        display:flex;
        align-items: center;
        .stepTitle {
            width:12%;
        }
        .fields {
            display:flex;
            flex-wrap: wrap;
            width:85%;
            .inputContainer {
                margin-right:20px;
                min-width: 45%;
                max-width: 45%;
                &.phone {
                    min-width: 92%;
                    max-width: 92%;
                }
            }
        }
        .vue-tel-input-vuetify {
           .v-text-field__details {
               display:none;
           }
           .v-input__slot {
                margin-bottom:0px !important;
            }
        }
    }


    .copyDiv {
        display:flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        background-color:rgba(255,255,255,0.8);
        width:100%;
        height:100%;
        top:0;
        left:0;
    }
    .fade-enter-active {
        transition: all .1s ease;
    }
    .fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .fade-enter, .fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        //transform: translateX(10px);
        opacity: 0;
    }

</style>
